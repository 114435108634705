<template>
  <div>
    <aside id="default-sidebar" class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 border-b lg:border-b-0 lg:border-r border-gray-200 dark:border-gray-800" aria-label="Sidebar">
      <div class="h-full px-3 py-4 overflow-y-auto border-r-1">
        <SideBar />
      </div>
    </aside>
    
    <div class="sm:ml-64">
      <Header></Header>
      <div class="p-8 ">
        <slot></slot>
      </div>
    </div>
    <UNotifications />
  </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss">
.dark {
  background: rgb(var(--color-gray-900) / 1);
  --color-primary-DEFAULT: var(--color-gray-900);
  --ui-background: var(--color-gray-900);
  --ui-foreground: var(--color-gray-200);
}
</style>